
<template>
	<div class="ele-body">
		<el-card shadow="never">
			<!-- 搜索表单 -->
			<el-form :model="table.where" class="ele-form-search d-flex f-end"
				@keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
				<div class="d-flex" style="display: flex;">



					<el-form-item label="姓名:" label-width="50px" class="w-200">
						<el-input v-model="table.where.name" placeholder="" clearable />
					</el-form-item>
					<el-form-item label="手机号:" label-width="70px" class="w-200">
						<el-input v-model="table.where.phone" placeholder="" clearable />
					</el-form-item>

					<el-form-item label="省:" label-width="43px" class="w-170">
						<el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)"
							placeholder='请选择省' clearable>
							<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
								:label="option.name">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="市:" label-width="43px" class="w-150">
						<el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid)"
							placeholder='请选择市' clearable>
							<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
								:label="option.name">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="县/区:" label-width="64px" class="w-200">
						<el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
							<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
								:label="option.name"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item style="margin-left: 20px" label="注册起止时间:" label-width="100px">
            
						<el-date-picker v-model="table.where.create_time"  type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
							placeholder="选择开始时间" clearable>
						</el-date-picker>
						至
						<el-date-picker v-model="table.where.update_time"  type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
							placeholder="选择结束时间" clearable>
						</el-date-picker>
            
            
					</el-form-item>

					<el-form-item label="用户分类:" label-width="80px">
						<el-select v-model="table.where.difference" placeholder="请选择" clearable class="ele-fluid">
							<el-option label="全部" value="" />
							<el-option label="小程序" value="1" />
							<el-option label="app" value="2" />
							<el-option label="电召用户" value="4" /> 
							<el-option label="老年人" value="5" />
						</el-select>
					</el-form-item>

					<el-form-item>
						<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
							class="ele-btn-icon ml-20">搜索</el-button>
						<el-button @click="initset">重置</el-button>
						<download ref="childDownload" style="margin-left: 0" class="ele-btn-icon ml-20" label="导出" url="user/exportExcel" :outtime='1000' :params="exportOrderParams()" />
						<!--                  <el-button icon="el-icon-download" type="primary" @click="exportExcel" v-if="permission.includes('sys:order:export')">导出</el-button>-->
					</el-form-item>
				</div>
			</el-form>

			<!-- 数据表格 -->
			<ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
				highlight-current-row :stripe=true>
				<template slot-scope="{index}">
					<el-table-column type="selection" width="45" align="center" fixed="left" />
					<el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
						show-overflow-tooltip />
					<el-table-column prop="headimg" label="头像" show-overflow-tooltip min-width="80">
						<template slot-scope="{row}">
							<el-image style="width:25px;height:25px;" :src="row.headimg" fit="cover"
								:preview-src-list="[row.headimg]">
								<div slot="error" class="image-slot">
									<i class="el-icon-picture-outline"></i>
								</div>
							</el-image>
						</template>
					</el-table-column>
					
					<el-table-column prop="name" label="昵称" show-overflow-tooltip min-width="160" />
					<el-table-column prop="phone" label="联系方式" show-overflow-tooltip min-width="120" />

					<!-- <el-table-column prop="wechat_id" label="用户分类" show-overflow-tooltip min-width="150">
						<template slot-scope="{row}">{{ row.wechat_id ? '小程序用户' : 'app用户'}}</template>
					</el-table-column> -->
					<el-table-column label="用户分类" show-overflow-tooltip min-width="120">
						<template slot-scope="{row}">
							{{ getDesc(row.user_type) }}
						</template>
					</el-table-column>
					<el-table-column prop="sex_text" label="性别" show-overflow-tooltip min-width="60" />
					<el-table-column prop="home" label="认证家乡" show-overflow-tooltip min-width="160" />
					<el-table-column label="注册时间" show-overflow-tooltip min-width="160">
						<template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
					</el-table-column>
					<el-table-column label="最近登录时间" show-overflow-tooltip min-width="160">
						<template slot-scope="{row}">{{ row.active_time*1000 | toDateString }}</template>
					</el-table-column>
					<el-table-column prop="orders" label="累计发单量(笔)" show-overflow-tooltip min-width="120" />
					<el-table-column prop="success_orders" label="成功叫单量(笔)" show-overflow-tooltip min-width="120" />
					<el-table-column prop="balck_count" label="被司拉黑次数（次）" show-overflow-tooltip min-width="160" />
					<!-- <el-table-column prop="success_orders" label="被司拉黑次数（次）" show-overflow-tooltip min-width="100" /> -->
					<el-table-column label="操作" width="200px" align="center" :resizable="false" fixed="right">
						<template slot-scope="{row}">
							<el-link @click="handlecheck(row)" icon="el-icon-view" type="primary" :underline="false"
								v-if="permission.includes('sys:user:view')">查看</el-link>
							<el-link @click="handleEdit(row)" icon="el-icon-edit" type="primary" :underline="false"
								v-if="permission.includes('sys:user:edit')">编辑</el-link>

								
							<el-dropdown @command="command => dropClick(command,row)">
								<span class="el-dropdown-link" style="margin-left: 10px;">
									<el-link type="primary" icon="el-icon-more" :underline="false"></el-link>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item icon="el-icon-delete" command="remove"
										v-if="permission.includes('sys:user:delete')">删除</el-dropdown-item>
										<!-- {{ row.is_old }}  -->

										<div v-if="row.is_old == 0">
											<el-dropdown-item icon="el-icon-user-solid" command="sign"
										v-if="permission.includes('sys:user:sign')">标记老年机</el-dropdown-item>
										</div> 
							        
									     <div v-if="row.is_old == 1">
											<el-dropdown-item icon="el-icon-user-solid" command="cancel"
										v-if="permission.includes('sys:user:cancel')">取消标记老年机</el-dropdown-item>
										 </div>

									<el-dropdown-item icon="el-icon-user-solid" command="handleUrgent"
										v-if="permission.includes('sys:user:handleUrgent')">紧急联系人</el-dropdown-item>
									<!--									<el-dropdown-item icon="el-icon-s-release" command="blacklist" v-if="permission.includes('sys:user:blacklist')">拉入黑名单</el-dropdown-item>-->
									<el-dropdown-item icon="el-icon-s-release" command="blackData"
										v-if="permission.includes('sys:user:blacklist')">拉入黑名单</el-dropdown-item>
									<el-dropdown-item icon="el-icon-s-custom" v-if="permission.includes('sys:user:black')" command="shieldDetail">
										被司拉黑明细
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</el-table-column>
				</template>
			</ele-data-table>
		</el-card>
		<!-- 查看用户详情 -->
		<el-dialog v-dialogDrag title="查看用户详情" :visible.sync="dialogTableVisibleCheck" width="780px"
			:destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="checkForm" label-width="150px">
					<div style="display: flex;">
						<div style="padding-right: 60px;border-right: 1px solid #C6C6C6;">
							<el-form-item label="用户昵称：" prop="name">
								{{checkForm.name}}
							</el-form-item>
							<el-form-item label="用户头像：" prop="headimg">
								<!-- <img :src="checkForm.headimg" width="70" height="70" /> -->
								<el-image style="width:70px;height:70px;" :src="checkForm.headimg" fit="cover"
									:preview-src-list="[checkForm.headimg]"></el-image>
							</el-form-item>
							<el-form-item label="用户手机号(座机号)：" prop="phone">
								{{checkForm.phone}}
							</el-form-item>
							<el-form-item label="认证家乡：" prop="home">
								{{checkForm.home}}
							</el-form-item>
							<el-form-item label="性别：" prop="sex">
								{{checkForm.sex_text}}
							</el-form-item>
							<div v-if="checkForm.difference == 'b' || checkForm.difference == 'ab' ">
								<el-form-item label="初次使用过时间：" prop="create_time">
									{{ checkForm.start_time*1000 | toDateString }}
								</el-form-item>
							</div>

						</div>
						<div>
							<el-form-item label="累计发单量：" prop="orders">
								{{checkForm.orders}} 笔
							</el-form-item>
							<el-form-item label="成功叫单量：" prop="success_orders">
								{{checkForm.success_orders}} 笔
							</el-form-item>
							<el-form-item label="注册时间：" prop="create_time">
								{{ checkForm.create_time*1000 | toDateString }}
							</el-form-item>
							<el-form-item label="最近使用时间：" prop="end_time">
								{{ checkForm.end_time*1000 | toDateString }}
							</el-form-item>
							<el-form-item label="用户余额：" prop="money">
								{{checkForm.money}}
							</el-form-item>
							<el-form-item label="用户类型：" prop="wechat_id">
								{{ getDesc(checkForm.user_type) }}
							</el-form-item>
							<div v-if="checkForm.difference == 'b' || checkForm.difference == 'ab' ">
								<el-form-item label="电召设备：" prop="home">
									{{checkForm.home}}
								</el-form-item>
							</div>




						</div>
					</div>
				</el-form>
			</el-card>
		</el-dialog>
		<!-- 编辑用户 -->
		<el-dialog v-dialogDrag title="编辑用户" :visible.sync="dialogTableVisibleEdit" :destroy-on-close="true"
			:lock-scroll="false" @closed="[editForm={},$refs.table.reload()]">
			<el-card shadow="never">
				<el-form :model="editForm" ref="editForm" label-width="170px" :rules="editRules">
					<el-form-item label="用户昵称：" prop="name">
						<el-input v-model="editForm.name" placeholder="" clearable style="width: 160px;" />
					</el-form-item>
					<el-form-item label="用户头像：">
						<uploadImage :limit="1" v-model="editForm.headimg"></uploadImage>
					</el-form-item>
					<el-form-item label="用户手机号" prop="phone">
						<el-input v-model="editForm.phone" placeholder="" clearable style="width: 160px;" />
					</el-form-item>
					<div>
						<el-form-item label="认证家乡：">
							<el-select v-model="editForm.pname" @change="handleChangeProv1(editForm.pname)"
								placeholder='请选择省' class="selectStyle mr-10 mb-20" clearable>
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.cname" @change="handleChangeCity1(editForm.cname)"
								placeholder="请选择市" class="selectStyle mr-10 mb-20" clearable>
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.aname" @change="$forceUpdate(),handleDis(editForm.aname)"
								placeholder="请选择区/县" class="selectStyle mr-10 mb-20" clearable>
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
					</div>
					<el-form-item label="性别：" prop="sex">
						<el-radio-group v-model="editForm.sex">
							<el-radio :label="1">男</el-radio>
							<el-radio :label="2">女</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="是否为老年人：" prop="is_old">
						<el-radio-group v-model="editForm.is_old">
							<el-radio :label="1">是</el-radio>
							<el-radio :label="0">否</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="累计发单量：" prop="orders">
						{{editForm.orders}} 笔
					</el-form-item>
					<el-form-item label="注册时间：" prop="create_time">
						{{ editForm.create_time*1000 | toDateString }}
					</el-form-item>
					<el-form-item label="最近活跃时间：" prop="active_time">
						{{ editForm.active_time*1000 | toDateString }}
					</el-form-item>
					<el-form-item label="用户余额：" prop="money">
						{{editForm.money}}
					</el-form-item>
				</el-form>
			</el-card>
			<div slot="footer">
				<el-button @click="dialogTableVisibleEdit=false">取消</el-button>
				<el-button type="primary" @click="save">保存</el-button>
			</div>
		</el-dialog>
		<!-- 紧急联系人 -->
		<el-dialog v-dialogDrag title="紧急联系人" :visible.sync="dialogTableVisibleUrgent" width="900px"
			:destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never">
				<el-table :data="urgentList" element-loading-text="列表正在加载中" border fit highlight-current-row
					style="width: 100%;">
					<el-table-column type="selection" width="45" align="center" fixed="left" />
					<el-table-column type="index" label="编号" width="60" align="center" fixed="left"
						show-overflow-tooltip />
					<el-table-column prop="uname" label="用户姓名" show-overflow-tooltip />
					<el-table-column prop="uphone" label="用户电话" show-overflow-tooltip />
					<el-table-column prop="name" label="联系人姓名" show-overflow-tooltip />
					<el-table-column prop="phone" label="联系人电话" show-overflow-tooltip />
					<el-table-column label="添加时间" show-overflow-tooltip min-width="120">
						<template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
					</el-table-column>

				</el-table>
			</el-card>



		</el-dialog>

		<!--    加入黑名单弹窗-->
		<el-dialog v-dialogDrag title="确认加入黑名单" :visible.sync="dialogVisiblePlans" width="800px"
			:destroy-on-close="true" :lock-scroll="false">
			<el-form :model="blackReason" ref="blackReason" @submit.native.prevent :rules="editRules" style="margin: 40px 0;">
				<el-form-item label="拉黑原因:" label-width="120px">
					<!-- <div v-if="blackReason.black_data!=''"> -->

					<el-input v-model="blackReason.black_data" placeholder="拉黑原因" style="width: 580px;" />
					<!-- </div> -->
				</el-form-item>
			</el-form>
			<div slot="footer">
				<el-button @click="dialogVisiblePlans=false">取消</el-button>
				<el-button type="primary" @click="blackData()">确认</el-button>
			</div>
		</el-dialog>
		<!-- 被司拉黑明细 -->
		<el-dialog v-dialogDrag title="被司拉黑明细" :visible.sync="dialogShieldDetail" width="1200px" :destroy-on-close="true"
			:lock-scroll="false">
			<div style="display: flex; margin: 20px 0;">
				<div class="info" style="font-size: 14px;color: #262626;font-weight:550">乘客昵称:{{ info.name }}</div>
				<div class="info" style="font-size: 14px;color: #262626;font-weight:550">账号:{{ info.phone }}</div>
			</div>
			<el-table :data="shieldList" element-loading-text="列表正在加载中" border fit highlight-current-row
				style="width: 100%;">
				<el-table-column type="selection" width="45" align="center" fixed="left" />
				<el-table-column type="index" label="序号" width="60" align="center" fixed="left" show-overflow-tooltip />
				<el-table-column prop="order_id" label="对应拉黑订单" align="center" show-overflow-tooltip min-width="200" />
				<el-table-column prop="driver_phone" label="司机账号" align="center" show-overflow-tooltip min-width="150" />
				<el-table-column prop="car_number" label="车牌号" align="center" show-overflow-tooltip min-width="150" />
				<el-table-column prop="black_reason" label="拉黑原因" align="center" show-overflow-tooltip min-width="200" />
				<el-table-column prop="create_time" label="拉黑时间" align="center" show-overflow-tooltip min-width="160" />
				<el-table-column prop="create_time" label="查看订单" align="center" show-overflow-tooltip min-width="180">
					<template slot-scope="{row}">
						<el-link @click="viewOrderChat(row)" icon="el-icon-view" type="primary" :underline="false"
								v-if="row.channel == 1">查看订单聊天</el-link>
						<el-link @click="viewOrderDetail(row)" icon="el-icon-edit" type="primary" :underline="false"
							v-if="row.channel == 2 || row.channel == 4">查看订单详情</el-link>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination v-if="shieldShow" @current-change="shieldCurrentChange" :current-page.sync="pageNumber"
				layout="total, prev, pager, next, jumper" :page-sizes="[5, 10, 15, 20]" :page-size="10"
				:total="count">
			</el-pagination>
		</el-dialog>
		<!-- 聊天记录 -->
		<el-dialog v-dialogDrag title="聊天记录" :visible.sync="dialogTableVisibleChatRecord" class="records"
			@close='closeDialog' :destroy-on-close="true" :lock-scroll="false" width="600px">
			<div class="charRecord-box">
				<div class="driverBox" style="padding:20px;width: 500px;">
					<div class="" style="width:80px;height:80px;">
						<img style="width:60px;height:60px;border-radius:50%" :src="driverInfo.headimg" />
					</div>
					<div class="">
						<div class="" style="font-size:17px;margin:5px 0">{{driverInfo.name}}</div>
						<div class="" style="font-size:13px">{{driverInfo.car_number}}</div>
					</div>
				</div>
				<div>
					<div class="charRecord-inner" v-for="(option,index) in charList" :key="option.id">
						<!-- 时间 -->
						<!-- <div class="w-100" v-if="option.isTimeShow==1 && option.p_show==1 && option.type !== 33 && option.type !== 34"> -->
						<div class="w-100"
							v-if="option.p_show==1 && option.type !== 33 && option.type !== 34 && option.content.S!=89 && option.content.S!=38 && option.content.S!=94 && option.content.S!=27">
							<div class="charRecord-time">{{option.create_time}}</div>
						</div>
						<!-- 系统消息-->
						<div class="w-100"
							v-if="(option.content.S==5 || option.content.S==100 || option.content.S==1 || option.content.S==20 || option.content.S==39) && option.p_show==1">
							<div class="charRecord-leftItem">
								<div class="charRecord-image">
									<img class="charHeadimg" src="./logo1.png">
								</div>
								<div class="hh"></div>
								<div class="charRecord-title5 p-10">{{option.content.M}}</div>
							</div>
						</div>
						<!-- 左侧 完善目的地-->
						<div class="w-100" v-if="option.content.S==27 && option.direction==3">
							<div class="charRecord-leftItem">
								<div class="charRecord-image">
									<img class="charHeadimg" src="./logo1.png">
								</div>
								<div class="hh"></div>
								<div class="charRecord-title ">
									<div class="">
										<div class="m-10 mb-0">您可以完善一下目的地</div>
										<div class="whiteAddress">{{orderInfo.place_address}}</div>
										<div class="addressBtn">
											<span class="ignore">忽略</span>
											<span class="submit">提交</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- 右侧 完善目的地-->
						<div class="w-100" v-if="option.content.S==27 && option.direction==1">
							<div class="charRecord-rightItem">
								<div class="charRecord-title2 ">
									<div class="">
										<div class="m-10 mb-0">您可以完善一下目的地</div>
										<div class="whiteAddress">请输入您要去的地址</div>
										<div class="addressBtn">
											<span class="ignore" style="width: 100%;border-right: none">已忽略</span>
										</div>
									</div>
								</div>
								<div class="rhh"></div>
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!--						右文字信息-->
						<div class="w-100" v-if="option.content.S==1">
							<div class="charRecord-rightItem">
								<div class="charRecord-title2 p-10">{{option.content.M}}</div>
								<div class="rhh"></div>
								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 左侧 语音-->
						<div class="w-100 " v-if="option.content.S==21 && option.direction==2">
							<div class="charRecord-leftItem">
								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.driver.headimg">
								</div>
								<div class="hh"></div>
								<div class="charRecord-title2 p-10 " :style="{width:option.bgWidth}"
									@click="handlePlay(option.content.P.URL,option.id,index)"
									v-if="option.isPlay==true ">
									<audio class="pasuVideo" :src="option.content.P.URL"
										:ref="'middle'+index">您的浏览器不支持</audio>
									<div class="lvoide1">
										<img src="../../../assets/images/ic_b_yuyin@2x.png">
									</div>
									<div>{{option.content.P.LONG}}''</div>
								</div>
								<div class="charRecord-title2 p-10" :style="{width:option.bgWidth}"
									v-if="option.isPlay==false">
									<audio class="pasuVideo" :src="option.content.P.URL"
										:ref="'middle'+index">您的浏览器不支持</audio>
									<div class="lvoide1">
										<img src="../../../assets/images/ic_b_yuyin.gif">
									</div>
									<div>{{option.content.P.LONG}}''</div>
								</div>
							</div>
						</div>
						<!-- 右侧 语音-->
						<div class="w-100 " v-if="option.content.S==21 && option.direction==1">
							<div class="charRecord-rightItem">
								<div class="charRecord-title2 p-10 bgGreen " :style="{width:option.bgWidth}"
									@click="handlePlay(option.content.P.URL,option.id,index)"
									v-if="option.isPlay==true">
									<audio class="pasuVideo" :src="option.content.P.URL"
										:ref="'middle'+index">您的浏览器不支持</audio>
									<div style="color:#fff">{{option.content.P.LONG}}''</div>
									<div class="rvoide1">
										<img src="../../../assets/images/yuyin-ico.png" :ref="'changeImg'+index">
									</div>

								</div>
								<div class="charRecord-title2 p-10 bgGreen" :style="{width:option.bgWidth}"
									v-if="option.isPlay==false">
									<audio class="pasuVideo" :src="option.content.P.URL"
										:ref="'middle'+index">您的浏览器不支持</audio>

									<div style="color:#fff">{{option.content.P.LONG}}''</div>
									<div class="rvoide1">
										<img src="../../../assets/images/yuyin-gif.gif">
									</div>

								</div>
								<div class="rhh1"></div>
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 图片-->
						<div class="w-100" v-if="option.content.S==22">
							<div class="charRecord-rightItem">
								<div class="charRecord-title1 p-10" @click="hanlePicture(option.content.P.URL)">
									<img class="" style="max-width: 100px;" :src="option.content.P.URL">
								</div>
								<div class="rhh"></div>
								<div class="charRecord-image">
									<img width='100px' height='100px' class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 视频-->
						<div class="w-100" v-if="option.content.S==6">
							<div class="charRecord-rightItem">
								<div class="charRecord-title1 p-10">
									<video width="100" height="100" controls>
										<source :src="option.content.P.URL" type="video/mp4">
									</video>
								</div>
								<div class="rhh"></div>
								<div class="charRecord-image">

									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 地图-->
						<div class="w-100" v-if="option.content.S==26 && option.direction==1">
							<div class="charRecord-rightItem">
								<div class="charRecord-titleMap p-10">
									<div class="amap-page-container">
										<div>{{option.content.P.ADDRESS}}</div>
										<div>{{option.content.P.TADDRESS}}</div>
										<div :style="{width:'100%',height:'180px'}">
											<!-- vid="amapDemo1" -->

											<el-amap ref="map" :zoom="zoom" :center="option.content.P.POINT">
												<el-amap-marker></el-amap-marker>
											</el-amap>
										</div>
									</div>
								</div>
								<div class="rhh"></div>
								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 左侧 地图-->
						<div class="w-100" v-if="option.content.S==26 && option.direction==2">
							<div class="charRecord-leftItem">
								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.driver.headimg">
								</div>
								<div class="hh"></div>
								<div class="charRecord-titleMap p-10">
									<div class="amap-page-container">
										<div :style="{width:'100%',height:'180px'}">
											<el-amap ref="map" :zoom="zoom" :center="option.content.P.POINT">
												<el-amap-marker></el-amap-marker>
											</el-amap>
										</div>
									</div>
								</div>


							</div>
						</div>

						<!-- 左侧 语音通话 对方已取消-->
						<div class="w-100"
							v-if="option.content.S==60 && option.direction==2 && option.content.P.call_status==3 && option.p_show==1">
							<div class="charRecord-leftItem">
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.driver.headimg">
								</div>
								<div class="hh"></div>

								<div class="charRecord-title2 p-10 disCenter voide">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/ic_phone3grey@2x.png">
									</div>
									<div>{{option.content.P.voiceCallMessage}}</div>
								</div>
							</div>
						</div>
						<!-- 左侧 语音通话 已拒接-->
						<div class="w-100"
							v-if="option.content.S==60 && option.direction==2 && option.content.P.call_status==4 && option.p_show==1">
							<div class="charRecord-leftItem">
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.driver.headimg">
								</div>
								<div class="hh"></div>

								<div class="charRecord-title2 p-10 disCenter voide">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/ic_phone3grey@2x.png">
									</div>
									<div>{{option.content.P.message}}</div>
								</div>
							</div>
						</div>
						<!-- 左侧 语音通话 未接听-->
						<div class="w-100"
							v-if="option.content.S==60 && option.direction==2 && option.content.P.call_status==5 && option.p_show==1">
							<div class="charRecord-leftItem">
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.driver.headimg">
								</div>
								<div class="hh"></div>

								<div class="charRecord-title2 p-10 disCenter voide">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/call_ic_blackwait.png">
									</div>
									<div>{{option.content.P.voiceCallMessage}}</div>
								</div>
							</div>
						</div>
						<!-- 左侧 语音通话 通话时长-->
						<div class="w-100"
							v-if="option.content.S==60 && option.direction==2 && option.content.P.call_status==6">
							<div class="charRecord-leftItem">
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.driver.headimg">
								</div>
								<div class="hh"></div>

								<div class="charRecord-title2 p-10 disCenter voide">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/ic_phonegrey@2x.png">
									</div>
									<div>{{option.content.P.message}}</div>
								</div>
							</div>
						</div>
						<!-- 右侧 语音通话 已取消-->
						<div class="w-100"
							v-if="option.content.S==60 && option.direction==1 && option.content.P.call_status==3 && option.p_show==1">
							<div class="charRecord-rightItem">
								<div class="charRecord-title2 p-10 disCenter voide bgGreen">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/ic_phone3@2x.png">
									</div>
									<div>{{option.content.P.message}}</div>
								</div>
								<div class="rhh1"></div>
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 语音通话 对方已拒接-->
						<div class="w-100"
							v-if="option.content.S==60 && option.direction==1 && option.content.P.call_status==4 && option.p_show==1">
							<div class="charRecord-rightItem">
								<div class="charRecord-title2 p-10 disCenter voide bgGreen">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/ic_phone3@2x.png">
									</div>
									<div>{{option.content.P.voiceCallMessage}}</div>
								</div>
								<div class="rhh1"></div>
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 语音通话 对方无应答-->
						<div class="w-100"
							v-if="option.content.S==60 && option.direction==1 && option.content.P.call_status==5 && option.p_show==1">
							<div class="charRecord-rightItem">
								<div class="charRecord-title2 p-10 disCenter voide bgGreen">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/call_ic_whitewait.png">
									</div>
									<div>{{option.content.P.message}}</div>
								</div>
								<div class="rhh1"></div>
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 语音通话 通话时长-->
						<div class="w-100"
							v-if="option.content.S==60 && option.direction==1 && option.content.P.call_status==6">
							<!--							v-if="option.content.S==60 && option.direction==1 && option.content.P.call_status==6 && option.p_show==1">-->
							<div class="charRecord-rightItem">
								<div class="charRecord-title2 p-10 disCenter voide bgGreen">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/ic_phone@2x.png">
									</div>
									<div>{{option.content.P.message}}</div>
								</div>
								<div class="rhh1"></div>
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 左侧 订单账单-->
						<div class="w-100" v-if="option.content.S==31">
							<div class="charRecord-leftItem">
								<div class="charRecord-image">
									<img class="charHeadimg" src="./logo1.png" />
								</div>
								<div class="hh"></div>
								<div class="charRecord-Paytitle">
									<div class="w-30">
                                         <div v-if="option.content.R==2">
											<div class="m-10 mb-0 text-center">空驶补偿账单</div>
										 </div>
							             <div v-else>
											<div class="m-10 mb-0 text-center">订单账单</div>
										 </div> 
										
										<div class="m-10 mb-0 text-center">
											<span class="price">{{option.show_data.price}}</span>元
										</div>
										<div class="p-10 text-center payBox">
											<div class="payTitle">行程车费</div>
											<div class="payPrice">{{option.show_data.price}}元</div>
										</div>
										<div class="p-10  payBox">
											<div class="payTitle">零钱抵现</div>
											<div class="payPrice"><span
													style="color: #FF9D00;">-{{option.show_data.bonus}}</span>元</div>
										</div>
										<div class="p-10 payType">
											<div>
												<img src="../../../assets/images/share_ic_wechat@2x.png"
													style="float: left;" alt="" width="30px" height="30px;">
												<span class="wxPay">微信支付</span>
											</div>

											<img src="../../../assets/images/ic_arrow@2x.png"
												style="float: right;margin-top: 3px;" alt="" width="10px"
												height="20px;">

										</div>
										<!--										<div class="p-10 payType borderTop">-->
										<!--											<div>-->
										<!--												<img src="../../../assets/images/ic_chat_pt_pay_alipay.png"-->
										<!--													style="float: left;" alt="" width="30px" height="30px;">-->
										<!--												<span class="wxPay">支付宝支付</span>-->
										<!--											</div>-->

										<!--											<img src="../../../assets/images/ic_arrow@2x.png"-->
										<!--												style="float: right;margin-top: 3px;" alt="" width="10px"-->
										<!--												height="20px;">-->
										<!--										</div>-->
										<div class="p-10 payType borderTop">
											<div>
												<img src="../../../assets/images/ic_pay@2x.png" style="float: left;"
													alt="" width="30px" height="30px;">
												<span class="wxPay">线下支付</span>
											</div>

											<img src="../../../assets/images/ic_arrow@2x.png"
												style="float: right;margin-top: 3px;" alt="" width="10px"
												height="20px;">
										</div>


									</div>
								</div>

							</div>
						</div>
						<!-- 左侧 违约金账单-->
						<div class="w-100" v-if="option.content.S==46">
							<div class="charRecord-leftItem">
								<div class="charRecord-image">
									<img class="charHeadimg" src="./logo1.png" />
								</div>
								<div class="hh"></div>
								<div class="charRecord-Paytitle">
									<div class="w-30">
										<div class="m-10 mb-0 text-center">违约金账单</div>
										<div class="m-10 mb-0 text-center">
											<span class="price">{{option.content.P.PRICE}}</span>元
										</div>
										<div class="p-10 payType">
											<div>
												<img src="../../../assets/images/share_ic_wechat@2x.png"
													style="float: left;" alt="" width="30px" height="30px;">
												<span class="wxPay">微信支付</span>
											</div>

											<img src="../../../assets/images/ic_arrow@2x.png"
												style="float: right;margin-top: 3px;" alt="" width="10px"
												height="20px;">

										</div>

									</div>
								</div>

							</div>
						</div>
						<!-- 右侧 违约金支付成功-->
						<div class="w-100" v-if="option.content.S==36 && option.p_show==1">
							<div class="charRecord-rightItem">
								<div class="charRecord-Paytitle ">
									<div>
										<div class="paySuccess">支付成功</div>
										<div class="money">
											<span
												style="color:#84B625;font-size:25px;text-align:center">{{option.content.PRICE}}</span>元
										</div>
									</div>

								</div>
								<div class="rhh"></div>
								<div class="charRecordRightImage">
									<img class="charHeadimg" :src="option.user.headimg" />
								</div>

							</div>
						</div>
						<!-- 右侧 支付成功-->
						<div class="w-100" v-if="option.content.S==32 && option.p_show==1">
							<div class="charRecord-rightItem">
								<div class="charRecord-Paytitle ">
									<div>
										<div class="paySuccess">支付成功</div>
										<div class="money">
											<span
												style="color:#84B625;font-size:25px;text-align:center">{{option.content.PRICE}}</span>元
										</div>
										<div class="otherPay">红包另支付0元</div>
									</div>

								</div>
								<div class="rhh"></div>
								<div class="charRecordRightImage">
									<img class="charHeadimg" :src="option.user.headimg" />
								</div>

							</div>
						</div>

						<!-- 左侧 被动红包 -->
						<div class="w-100" v-if="option.content.S==72">
							<div class="charRecord-leftItem">
								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.driver.headimg">
								</div>
								<div class="hh"></div>
								<div class="charRecord-Paytitle">
									<div class="">
										<div class="text-align pbt-10">对话红包</div>
										<div class="bill-box">
											<div class="">红包金额</div>
											<div class=""><span>{{option.content.P.PRICE}}</span>元</div>
										</div>
										<div class="btn">
											<div class="btn-ignore">驳回</div>
											<div class="line"></div>
											<div class="btn-ignore">发红包</div>
										</div>

									</div>
								</div>
							</div>
						</div>
						<!-- 右侧 被动红包-->
						<div class="w-100" v-if="option.content.S==69">
							<div class="charRecord-rightItem">
								<div class="charRecord-Paytitle">
									<div class="">
										<div class="text-align pbt-10">对话红包</div>
										<div class="bill-box">
											<div class="">红包金额</div>
											<div class=""><span>0.01</span>元</div>
										</div>
										<div class="btn">
											<div class="btn-ignore text-align">已驳回</div>
										</div>
									</div>
								</div>
								<div class="rhh"></div>
								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 未领取主动红包-->
						<div class="w-100" v-if="option.content.S==71 && option.content.H==1">
							<div class="charRecord-rightItem">
								<div class="charRecord-title3">
									<img src="../../../assets/images/bg_hb1@2x.png"
										style="position:relative;width:100%;height:100%" />

									<div class="red-box" style="position:absolute;top:30px">
										<div>
											<img class="package" src="../../../assets/images/img_hb1@2x.png" />
										</div>
										<div class="">
											<div class="">主动红包，司机可领取</div>
											<div class="font--9">未领取</div>
										</div>
									</div>
									<div class="address-box" style="position:absolute;top:64px">
										<div class="">
											<img class="weizhi" src="../../../assets/images/hb_logo@2x.png" />
										</div>
										<div class="font--9">咱县打车</div>
									</div>
								</div>

								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 已领取主动红包-->
						<div class="w-100" v-if="option.content.S==71 && option.content.H==2">
							<div class="charRecord-rightItem">
								<div class="charRecord-title3">
									<img src="../../../assets/images/bg_hb2@2x.png"
										style="position:relative;width:100%;height:100%" />

									<div class="red-box" style="position:absolute;top:30px">
										<div>
											<img class="package" src="../../../assets/images/img_hb2@2x.png" />
										</div>
										<div class="">
											<div class="">主动红包，司机可领取</div>
											<div class="font--9">未领取</div>
										</div>
									</div>
									<div class="address-box" style="position:absolute;top:64px">
										<div class="">
											<img class="weizhi" src="../../../assets/images/hb_logo@2x.png" />
										</div>
										<div class="font--9">咱县打车</div>
									</div>
								</div>

								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="ampBox" :style="{display:ampDisplay==true?'block':'none'}" @click="handleClose">
				<img :src="ampSrc">
			</div>
		</el-dialog>
		<!-- 查看订单详情 -->
		<el-dialog v-dialogDrag title="查看订单详情" :visible.sync="dialogViewOrderDetail" @close='closeShow'
		:destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never" v-if="isReloadData">
				<el-form :model="telephoneCallForm" label-width="140px">
				<div style="display: flex;">
					<div style="width:100%;box-sizing: border-box;padding: 0 50px 0 10px;">
					<el-form-item label="发单时间：" prop="create_time">
						{{ telephoneCallForm.create_time * 1000 | toDateString }}
					</el-form-item>
					<el-form-item label="订单号：" prop="order_id">
						{{ telephoneCallForm.order_id }}
					</el-form-item>
					<el-form-item label="订单类型：" prop="type_name">
						{{ telephoneCallForm.type_name }}
					</el-form-item>
					<el-form-item label="发单起点所在地：" prop="place_address">
						{{ telephoneCallForm.place_address }}
					</el-form-item>
					<el-form-item label="发单终点所在地：" prop="target_address">
						{{ telephoneCallForm.target_address }}
					</el-form-item>
					<el-form-item label="语音时长：" prop="jiequ_audio_length">
						{{ telephoneCallForm.jiequ_audio_length }}秒
					</el-form-item>
					<el-form-item label="用户语音：" prop="jiequ_audio">
						<audio controls="controls" v-if="isReloadData">
						<source :src="telephoneCallForm.jiequ_audio" type="audio/ogg">
						<source :src="telephoneCallForm.jiequ_audio" type="audio/mpeg">
						</audio>
					</el-form-item>
					<el-form-item label="接单司机：" prop="driver_name">
						{{ telephoneCallForm.driver_name }}
					</el-form-item>
					<el-form-item label="司机手机号：">
						{{ telephoneCallForm.driver_phone }}
					</el-form-item>
					<el-form-item label="接单时间：" prop="receive_time">
						{{ telephoneCallForm.receive_time * 1000 | toDateString }}
					</el-form-item>
					<el-form-item label="抢单司机位置：" prop="order_price">
						{{ telephoneCallForm.grab_driver_address }}
					</el-form-item>
					<!-- <el-form-item label="完成时间：" prop="complete_time">
										{{ telephoneCallForm.complete_time*1000 | toDateString }}
									</el-form-item> -->
					<el-form-item label="支付方式：" prop="remark">
						{{ telephoneCallForm.remark }}
					</el-form-item>
					<el-form-item label="订单状态：" prop="status_name">
						{{ telephoneCallForm.status_name }}
					</el-form-item>
					</div>
				</div>
				</el-form>
			</el-card>
		</el-dialog>
	</div>
</template>

<script>
import {
	mapGetters
} from "vuex";
import uploadImage from '@/components/uploadImage';
import download from '@/components/ListDownload/index'
export default {
	name: "SysUser",
	components: {
		uploadImage,
		download
	},
	data() {
		return {
			changePro: false,
			table: {
				url: '/user/index',
				where: {}
			}, // 表格配置
			choose: [], // 表格选中数据
			editForm: {}, // 表单数据
			checkForm: {},
			telephoneCallForm: {}, //95128详情
			editRules: { // 表单验证规则
				phone: [{
					required: true,
					message: '请输入手机号',
					trigger: 'blur'
				}],
				
				name: [{
					required: true,
					message: '请输入昵称',
					trigger: 'blur'
				}],
				sex: [{
					required: true,
					message: '请选择性别',
					trigger: 'change'
				}],
				status: [{
					required: true,
					message: '请选择是否启用',
					trigger: 'change'
				}],
			},
			dialogTableVisibleCheck: false,
			dialogTableVisibleEdit: false,
			dialogTableVisibleUrgent: false,
			dialogShieldDetail: false,
			dialogTableVisibleChatRecord: false,
			dialogViewOrderDetail: false,
			provArr: [],
			cityArr: [],
			districtArr: [],
			urgentList: [],
			imageUrl: false,
			headImg: '',
			pid1: '',
			cid1: '',
			aid1: '',
			dialogVisiblePlans: false,
			blackReason: {},
			pageSize: 10,
			pageNumber: 1,
			shieldShow: false,
			shieldId: '',
			count: 0,
			shieldList: [],
			info: {
				name: '',
				phone: '',
			},
			zoom: 12,
			charList: [],
			driverInfo: {},
			ampDisplay: false,
			ampSrc: '',
			orderInfo: {},
			isReloadData: true,
		}
	},
	created() {
		this.$http.get('/common/province_list').then(res => {
			let data = JSON.parse(res.data)
      
      //console.log('-----------5566777---------',data);
			this.provArr = data
		})

	},


	computed: {
		...mapGetters(["permission"]),
	},

	mounted() { },
	methods: {
		getDesc(value ,filter = null) {
			function isBitOpen(value, position) {
				var mask = 1 << (position - 1); // 创建掩码，将1移位到指定位置
				return (value & mask) !== 0; // 使用位与运算检查指定位置是否为1
			}
			var descMap = {
					1: "小程序用户",
					2: "App用户",
					3: "焦作用户",
					4: "电召用户",
					5: "(老年人)"
			}
			let descArr = [];
			for (let i = 1; i < 6; i++){
					if (filter) {
							if (filter.indexOf(i) && isBitOpen(value,i)) {
									descArr.push(descMap[i]);
							}
					} else if (isBitOpen(value,i)) {
							descArr.push(descMap[i]);
					}
			}
			return descArr.join("/").replace("电召用户/(", "电召用户(");
		},
    initset(){
      this.table.where={};
      this.$refs.table.reload();
      this.$refs.childDownload.clearWhere();
      
    },

	handleClose() {
		this.ampDisplay = false
	},
	hanlePicture(src) {
		this.ampDisplay = true
		this.ampSrc = src
	},
	//拉黑查看订单聊天
	viewOrderChat(row){
		console.log('row',row);
		let that = this;
		that.dialogTableVisibleChatRecord = true;
		that.$http.post('/driver/getOrderLog', {
			channel: row.channel,
			order_id: row.order_id
		}).then(res => {
			console.log('获取聊天的res',res);
			if(res.data.code == 0){
				let data = res.data.data;
				data.log.forEach(function(item) {
					item.content=that.$util.jsonToObject(item.content);
					item.isTimeShow = 1
					if (item.content.S == 21) {
						item.content.P=that.$util.jsonToObject(item.content.P);
						item.isPlay = true
						if (item.content.P.LONG == 1) {
							item.bgWidth = '8rem'
						}
						item.bgWidth = 8 + 0.05 * (parseInt(item.content.P.LONG) - 1) + 'rem'
					}
					if (item.content.S == 26) {
						item.content.P.POINT = item.content.P.POINT.split(',')
						//console.log(item.content.P.POINT)
					}
				});
				for (var i = 0; i < data.log.length; i++) {
					for (var j = i + 1; j < data.log.length - 1; j++) {
						if (data.log[i].create_time == data.log[j].create_time) {
							data.log[j].isTimeShow = 0 //不显示时间
						} else {
							data.log[j].isTimeShow = 1
						}
					}
				}
				that.charList = data.log
				that.driverInfo = data.driver
				that.orderInfo = data.order_info
			} else {
				this.$message.error(res.data.msg);
			}
			
		})
	},
	//拉黑查看订单详情
	viewOrderDetail(row){
		console.log('row',row);
		let that = this;
		that.dialogViewOrderDetail = true;
		that.$http.post('/driver/getOrderLog', {
			channel: row.channel,
			order_id: row.order_id
		}).then(res => {
			console.log('95128订单详情的res',res);
			if(res.data.code == 0){
				that.telephoneCallForm = res.data.data;
				console.log('that.telephoneCallForm',that.telephoneCallForm);
			} else {
				this.$message.error(res.data.msg);
			}
		})
	},
	closeDialog() {
		var middlePlay = document.querySelectorAll('.pasuVideo')
		for (let i = 0; i < middlePlay.length; i++) {
			middlePlay[i].pause()
			middlePlay[i].currentTime = 0
		}
	},
	closeShow() {
		var middlePlay = document.querySelectorAll('.pasuVideo')
		for (let i = 0; i < middlePlay.length; i++) {
		middlePlay[i].currentTime = 12
		middlePlay[i].pause()
		}
		this.autoPlays = false
	},
		// 导出数据参数
		exportOrderParams() {
			const query = this.table.where
		  const selectionIds = this.choose.map(item => {
				return item.id
			})
      return {
				ids: selectionIds,
				...query
			}
		},
		dropClick(command, row) {
			if (command === 'handlecheck') { // 查看用户信息
				this.handlecheck()
			} else if (command === 'remove') { //删除
				this.remove()
			} else if (command === 'handleUrgent') { //紧急联系人
				this.handleUrgent(row)
			} else if (command === 'handleEdit') { //修改用户信息
				this.handleEdit(row)
			} else if (command === 'blacklist') {
				this.blacklist(row)
			} else if (command === 'blackData') { //拉入黑名单
				this.dialogVisiblePlans = true
				this.blackReason = row
			}else if (command === 'sign'){
				this.sign(row)
			}else if (command === 'cancel'){
				this.cancel(row)
			} else if(command === 'shieldDetail'){
				this.shieldDetail(row)
			}
		},

		/**
		 *选择省
		 **/
		handleChangeProv(e) {
			/** 获取被选省份的pid**/
			this.changePro = true
			let pid = ''
			this.provArr.forEach(function (item) {
				if (item.pid == e) {
					pid = item.pid
				}
			})
			/** 根据被选省份的pid获取省市下面的市**/
			this.$http.post('/common/city_list', {
				pid: pid
			}).then(res => {
				let data = JSON.parse(res.data)
				this.cityArr = data
				/** 选择省份清空市县**/
				this.table.where.cid = ''
				this.table.where.aid = ''
			})
		},
		/**
		 *选择市
		 **/
		handleChangeCity(e) {
			if (e) {
				/** 获取被选市的cid**/
				this.changePro = true
				let cid = ''
				this.cityArr.forEach(function (item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.table.where.aid = ''
				})
			} else {
				this.table.where.pid = ''
				this.table.where.aid = ''
			}
		},


		/**
		 *修改省
		 **/
		handleChangeProv1(e) {
			/** 获取被选省份的pid**/
			let pid = ''
			let that = this
			this.changePro = true
			this.provArr.forEach(function (item) {
				if (item.pid == e) {
					pid = item.pid
				}
			})
			/** 根据被选省份的pid获取省市下面的市**/
			this.$http.post('/common/city_list', {
				pid: pid
			}).then(res => {
				let data = JSON.parse(res.data)
				that.cityArr = data
				/** 选择省份清空市县**/
				this.editForm.cname = ''
				this.editForm.aname = ''
			})
		},
		/**
		 *修改市
		 **/
		handleChangeCity1(e) {
			if (e) {
				/** 获取被选市的cid**/
				let cid = ''
				this.changePro = true
				this.cityArr.forEach(function (item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.editForm.aname = ''
				})
			} else {
				this.editForm.pname = ''
				this.editForm.aname = ''
			}
		},
		handleDis() {
			this.changePro = true
		},
		/**
		 * 被司拉黑明细
		 */
		 shieldDetail(row){
			console.log('拉黑明细的row',row);
			this.dialogShieldDetail = true;
			this.shieldId = row.id;
			this.shieldDetailList(row.id);
		},
		//拉黑分页
		shieldCurrentChange(e){
			this.pageNumber = e;
			this.shieldDetailList(this.shieldId)
		},
		//拉黑明细列表
		shieldDetailList(id){
			this.shieldList = [];
			this.$http.get('/user/blackDetail?id=' + id + '&limit=' + this.pageSize + '&page=' + this.pageNumber).then(res => {
				console.log('拉黑的res',res)
				if(res.data.code == 0){
					this.info.name = res.data.data.data.name;
					this.info.phone = res.data.data.data.user_phone;
					this.count = res.data.count;
					if (res.data.count == 0 || res.data.count == 'null') {
						this.shieldShow = false
					} else {
						this.shieldShow = true
					}
					if (res.data.data.list.length) {
						this.shieldList = res.data.data.list;
					}
				}
			})
		},
		/**
		/**
		  *查看用户信息
		**/
		handlecheck(row) {
			this.dialogTableVisibleCheck = true
			if (row.status == 2) {
				row.status = '正常'
			} else (
				row.status = '待完善信息'
			)
			this.checkForm = row;
		},
		/**
		 *修改用户信息
		 **/
		handleEdit(row) {
			this.dialogTableVisibleEdit = true
			this.editForm = row;

			if (this.editForm.pid > 0) {
				this.$http.post('/common/city_list', {
					pid: this.editForm.pid
				}).then(res => {
					let cdata = JSON.parse(res.data)
					this.cityArr = cdata
				})
			}
			if (this.editForm.cid > 0) {
				this.$http.post('/common/area_list', {
					cid: this.editForm.cid
				}).then(res => {
					let adata = JSON.parse(res.data)
					this.districtArr = adata
				})
			}

		},
		/**
		 *紧急联系人
		 **/
		handleUrgent(row) {
			this.dialogTableVisibleUrgent = true
			this.$http.post('/user/urgentList', {
				id: row.id
			}).then(res => {
				//console.log(res.data)
				if (res.data.code === 0) {
					res.data.data.forEach(function (item) {
						item.uname = row.name
						item.uphone = row.phone
					})
					this.urgentList = res.data.data


				} else {
					this.$message.error(res.data.msg);
				}
			})
		},

		/**
		 * 保存编辑
		 */
		save() {
			this.$refs['editForm'].validate((valid) => {
				if (valid) {
					const loading = this.$loading({
						lock: true
					});
					//console.log('----save-----data',this.editForm)
					if (this.changePro == true) {
						this.editForm.pid = this.editForm.pname
						this.editForm.cid = this.editForm.cname
						this.editForm.aid = this.editForm.aname
						this.changePro = false
					}
					this.$http.post('/user/edit', this.editForm).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.dialogTableVisibleEdit = false;
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							if (this.editForm.sex == '1') {
								this.editForm.sex_text = '男'
							} else {
								this.editForm.sex_text = '女'
							}
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				} else {
					return false;
				}
			});
		},
		/**
		 * 刪除(批量刪除)
		 */
		remove(row) {
			if (!row) { // 批量删除
				if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
				let ids = this.choose.map(d => d.id);
				this.$confirm('确定要删除选中的用户吗?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/user/delete', {
						id: ids
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				}).catch(() => 0);
			} else { // 单个删除
				this.$confirm('确定要删除选中的用户吗?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/user/delete', {
						id: row.id
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			}
		},
				/**
		 * 标记为老年机
		 */
		 sign(row) {
			if (!row) { // 批量删除
				if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
				let ids = this.choose.map(d => d.id);
				this.$confirm('确定要标记选中的用户吗?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/user/signUser', {
						id: ids
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				}).catch(() => 0);
			} else { // 单个删除
				this.$confirm('确定要标记选中的用户吗?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/user/signUser', {
						id: row.id
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			}
		},
						/**
		 * 取消标记老年机
		 */
		 cancel(row) {
			if (!row) { // 批量删除
				if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
				let ids = this.choose.map(d => d.id);
				this.$confirm('确定要取消选中的用户吗?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/user/signCancelUser', {
						id: ids
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				}).catch(() => 0);
			} else { // 单个删除
				this.$confirm('确定要取消选中的用户吗?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/user/signCancelUser', {
						id: row.id
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			}
		},
		/**
		 * 拉入黑名单
		 */
		blacklist(row) {
			this.$confirm('确认把该用户拉入黑名单?', '提示', {
				type: 'warning'
			}).then(() => {
				const loading = this.$loading({
					lock: true
				});
				this.$http.post('/user/set_blacklist', {
					id: row.id
				}).then(res => {
					loading.close();
					if (res.data.code === 0) {
						this.$message({
							type: 'success',
							message: res.data.msg
						});
						this.$refs.table.reload();
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(e => {
					loading.close();
					this.$message.error(e.message);
				});
			})
		},
		// 新加加入黑名单  2022-05-07 13:59:17
		blackData() {
			const loading = this.$loading({
				lock: true
			});
			this.$http.post('/user/set_blacklist', {
				id: this.blackReason.id,
				black_data: this.blackReason.black_data
			}).then(res => {
				loading.close();
				if (res.data.code === 0) {
					this.$message({
						type: 'success',
						message: res.data.msg
					});
					this.dialogVisiblePlans = false;
					this.$refs.table.reload();
				} else {
					this.$message.error(res.data.msg);
				}
			}).catch(e => {
				loading.close();
				this.$message.error(e.message);
			});
		},
		/* 导出数据Excel */
		exportExcel() {
			let info = JSON.parse(JSON.stringify(this.table.where));
			
			//console.log('user data export')
			this.$http
			
				.get("/user/exportExcel", info)
				.then((res) => {
					let data = res.data;
					if (data.code == 0) {
						// 下载文件
						window.location.href = data.data;
						this.$message({
							type: "success",
							message: "导出成功",
						});
					} else {
						this.$message.error("导出失败");
					}
				})
		},
	}
}
</script>

<style scoped>
.ele-block .el-upload-dragger {
	width: 100%;
}

.changeStyle {
	width: 150px;
	margin-right: 30px;
}

.el-upload-list el-upload-list--text {
	display: none !important
}

/* /deep/.el-dialog {
	margin-top: 40px !important;
} */
.el-table + .el-pagination{
	margin: 15px 0;
}
.info{
	font-size: 18px;
	color: #000;
	margin: 0 30px;
}
.info:first-child{
	margin: 0 10px 0 10px;
}
/deep/.el-dialog {
  margin-top: 0px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
</style>
